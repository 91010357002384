<script>
import i18n from "../i18n";
import * as moment from "moment";
import {
  SET_USER_LANGUAGE,
  SET_SANDBOX_MODE_ALERT,
  UPDATE_MERCHANT_ENTITY_DETAILS,
  FETCH_NOTIFICATIONS,
  MARK_NOTIFICATION_READ,
  READ_ALL_NOTIFICATIONS,
} from "@/state/actions.type";
import SwitchInput from './switch-input.vue';
export const CHAIPAY_APP_URL = "https://www.docs.portone.cloud/";
export default {
  components: {
    SwitchInput
  },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/eng.png"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/vietnam_rounded.png"),
          language: "vn",
          title: "Vietnam"
        },
        {
          flag: require("@/assets/images/flags/thailand.jpeg"),
          language: "th",
          title: "Thailand"
        },
        {
          flag: require("@/assets/images/flags/korea_rounded.jpg"),
          language: "kr",
          title: "Korean"
        },
        // {
        //   flag: require("@/assets/images/flags/Kenya.png"),
        //   language: "ke",
        //   title: "Kenya"
        // },
        // {
        //   flag: require("@/assets/images/flags/south_africa.png"),
        //   language: "za",
        //   title: "South Africa"
        // },
        // {
        //   flag: require("@/assets/images/flags/nigeria.png"),
        //   language: "ng",
        //   title: "Nigeria"
        // },
        // {
        //   flag: require("@/assets/images/flags/Kenya.png"),
        //   language: "ke",
        //   title: "Kenya"
        // },
        // {
        //   flag: require("@/assets/images/flags/south_africa.png"),
        //   language: "za",
        //   title: "South Africa"
        // },
        // {
        //   flag: require("@/assets/images/flags/nigeria.png"),
        //   language: "ng",
        //   title: "Nigeria"
        // },
        {
          flag: require("@/assets/images/flags/indonesia.png"),
          language: "id",
          title: "Indonesian"
        },
        // {
        //   flag: require("@/assets/images/flags/japan.jpg"),
        //   language: "ja",
        //   title: "Japanese"
        // },
        // {
        //   flag: require("@/assets/images/flags/philippines.jpg"),
        //   language: "fi",
        //   title: "Philippines"
        // },
        // {
        //   flag: require("@/assets/images/flags/malaysia.svg"),
        //   language: "mly",
        //   title: "Malay"
        // },
        // {
        //   flag: require("@/assets/images/flags/french.jpg"),
        //   language: "fr",
        //   title: "French"
        // },
        // {
        //   flag: require("@/assets/images/flags/spain.jpg"),
        //   language: "es",
        //   title: "spanish"
        // },
        // {
        //   flag: require("@/assets/images/flags/chaina.png"),
        //   language: "zh",
        //   title: "Chinese"
        // },
        // {
        //   flag: require("@/assets/images/flags/arabic.png"),
        //   language: "ar",
        //   title: "Arabic"
        // }
      ],
      current_language: null,
      docs_link: CHAIPAY_APP_URL,
      polling: null,
      page: 1,
      pagesize: 10,
      showAllNotifications: true,
      showNotifications: false,
      fetchingNotifications: false,
      liveMode: this.$store.state.auth.currentUser.is_live_mode
    };
  },
  computed: {
    userRoles() {
      return this.$store.getters["auth/getUserRolesList"];
    },
    isAdminUser() {
      return this.userRoles.includes('admin') || this.userRoles.includes('sub-admin')
    },
    unreadCounts() {
      if(this.$store.state.notifications.content.length > 0) {
        return this.$store.state.notifications.content.filter(notification => !notification.read).length
      }
      return 0;
    },
    isOnboardingCompleted() {
      return this.$store.state.auth.currentUser.is_onboarding_completed;
    },
    notifications() {
      return this.$store.state.notifications.content;
    },
    allNotificationsCount() {
      return this.$store.state.notifications.content.length;
    },
    totalNotifications() {
      return this.$store.state.notifications.totalElements;
    },

  },
  filters: {
    date(date) {
      return moment(date).format("MMM DD, YYYY HH:mm:ss");
    }
  },
  created() {
    let lang = this.languages.find(el => el.language === this.$store.state.auth.language);
    this.current_language = lang || this.languages[0];
    i18n.locale = this.current_language.language;
    if(this.userRoles.includes('admin') || this.userRoles.includes('sub-admin')) {
      this.fetchNotifications();
  
      this.polling = setInterval(() => {
        this.fetchNotifications();
      }, 300000);
    }
  },
  watch: {
    liveMode() {
      if(this.isAdminUser) {
        this.$store.dispatch(`merchant/${UPDATE_MERCHANT_ENTITY_DETAILS}`, { is_live_mode: this.liveMode }).then(() => {
          this.hideSandBoxModeAlert(this.liveMode);
        })
        .catch(error => {
          this.$notify({
            type: "error",
            text: error.Message,
            closeOnClick: true,
          })
        })
        .finally(() => window.location.reload());
      }
    },
  },
  methods: {
    fetchNotifications(reset = false) {
      this.fetchingNotifications = true;

      let payload = {
        page: this.page,
        pagesize: this.pagesize,
        filters: {},
        showNotifications: this.showNotifications,
        reset: reset
      };

      if(!this.showAllNotifications) {
        payload.filters.unread_only = true;
      }

      this.$store.dispatch(`notifications/${FETCH_NOTIFICATIONS}`, payload).finally(() => this.fetchingNotifications = false);
    },
    readAllNotification() {
      if(this.unreadCounts > 0) {
        this.page = 1;
        this.$store.dispatch(`notifications/${READ_ALL_NOTIFICATIONS}`)
        .then(() => this.fetchNotifications(true));
      }
    },
    setLanguage(locale) {
      i18n.locale = locale.language;
      this.current_language = locale;
      this.$store.dispatch(`auth/${SET_USER_LANGUAGE}`, this.current_language.language);
    },
    hideSandBoxModeAlert(value) {
      this.$store.dispatch(`auth/${SET_SANDBOX_MODE_ALERT}`, value);
    },
    async openTransactionDetails(item) {
      if(!item.read) {
        await this.$store.dispatch(`notifications/${MARK_NOTIFICATION_READ}`, item.uuid).then(() => {
          this.fetchNotifications()
        });
      }
      this.$router.push(`/transactions/payments/payment-details?order_ref=${item.metadata.portone_order_ref}`);
    },
    loadMoreNotifications(el) {
      if((Math.ceil(el.srcElement.offsetHeight) + Math.ceil(el.srcElement.scrollTop)) >= el.srcElement.scrollHeight) {
          if(this.$store.getters['notifications/moreNotificationsAvailable']) {
            this.page++;
            this.fetchNotifications();
          }
        }
    },
    toggleNotifications() {
      console.log('in toggle notifications');
      this.showNotifications = !this.showNotifications;
      if(!this.showNotifications) {
        this.page = 1;
        this.fetchNotifications(true);
        this.polling = setInterval(() => {
          this.fetchNotifications();
        }, 300000);
      } else {
        clearInterval(this.polling)
        this.fetchNotifications(true);
      }
    },
    toggleShowAll(value) {
      if(this.showAllNotifications !== value) {
        this.showAllNotifications = value;
        this.page = 1;
        this.fetchNotifications(true);
      }
    },
    viewAll() {
      setTimeout(() => {
        this.toggleShowAll(true);
      }, 100);
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  directives: {
    'click-outside': {
      bind (el, binding, vnode) {
        const handleOutsideClick = (e) => {
          e.stopPropagation()
          const { handler, exclude } = binding.value
          const excludedEl = vnode.context.$refs[exclude]

          let clickedOnExcludedEl = !excludedEl || excludedEl.contains(e.target)

          if (!el.contains(e.target) && !clickedOnExcludedEl && vnode.context.showNotifications) {
            vnode.context[handler]();
          }
        }
        document.addEventListener('click', handleOutsideClick)
        document.addEventListener('touchstart', handleOutsideClick)
      },
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div>
        <p class="page-title">{{ $route.meta.title }}</p>
        <p class="page-title-details mb-0">{{ ['Dashboard', 'Transactions', 'Payouts', 'Analytics'].includes($route.name) ? ( $route.name === 'Dashboard' ? `Hello, ${$store.state.auth.currentUser.name}! ` : `Hey, ${$store.state.auth.currentUser.name}! `) : '' }}{{ $route.meta.description || '' }}</p>
        <!-- Look at the  high-level summary of your account here -->
      </div>
      <div class="d-flex ml-auto flex-wrap" style="gap: 8px;">
        <!-- all notifications -->
        <div
          v-click-outside="{
            exclude: 'notification-button',
            handler: 'toggleNotifications'
          }"
        >
        <transition name="fade">
          <b-card
            v-if="showNotifications"
            style="top: 90px; right: 25px; z-index: 5; width: 394px;border-radius: 15px; background: #FFF; box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);"
            class="position-absolute float-right bg-white"
            body-class="p-0"
          >
          <div>
            <header style="padding: 35px 25px;" class="d-flex justify-content-between">
              <label class="mb-0" style="font-size: 18px; line-height: 20px;">Notifications</label>
              <span class="cursor-pointer text-chai my-auto font-12px" @click="readAllNotification">
                Mark all as read
              </span>
            </header>
  
            <div style="margin-left: 10px; margin-right: 25px;">
              <span class="px-3 font-12px" style="line-height: 20px; padding: 0 10px 15px 10px;" :style="showAllNotifications ? 'color: #000; border-bottom: 2px solid #FC6B2D;' : 'color: rgba(0, 0, 0, 0.50);'" @click="toggleShowAll(true)" type="button">
                All
              </span>
              <span class="px-3 font-12px" style="line-height: 20px; padding: 0 10px 15px 10px;" :style="!showAllNotifications ? 'color: #000; border-bottom: 2px solid #FC6B2D;' : 'color: rgba(0, 0, 0, 0.50);'" @click="toggleShowAll(false)" type="button">
                New
              </span>
            </div>
            <div class="border-top" style="overflow-y: scroll; height: 50vh; border-color: #EEECE9;" @scroll="loadMoreNotifications">
              <transition name="fade">
                <div v-if="notifications.length > 0" class="d-flex" style="flex-direction: column;">
                  <div v-for="(notification, index) in notifications" :key="index" style="border-color: rgba(220, 218, 213, 0.30) !important; padding: 25px 25px; width: 394px;" class="border-bottom" :class="{'unread-notification': !notification.read}">
                    <b-card-text class="notification-date font-12px" style="margin-bottom: 5px;">{{ notification.created_at | date }}</b-card-text>
                    <b-card-text @click="openTransactionDetails(notification)" class="mb-0 font-12px cursor-pointer" style="font-weight: 325; line-height: 20px;">
                      {{ notification.metadata.message ? notification.metadata.message : notification.metadata.merchant_order_ref }}
                    </b-card-text>
                  </div>
                  <div class="d-flex justify-content-center" v-if="fetchingNotifications" >
                    <b-spinner label="Loading..."></b-spinner>
                  </div>
                  <span v-show="!$store.getters['notifications/moreNotificationsAvailable']" class="p-3 pb-4 text-chai text-center font-14px">That's all your notifications from the last 30 days.</span>
                </div>
              </transition>
              <transition name="fade">
                <div class="text-center d-flex flex-column justify-content-between h-100" v-if="notifications.length === 0">
                  <div class="my-auto mx-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" width="114" height="87" viewBox="0 0 114 87" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M46.0454 67.1446C44.9236 65.9486 36.8999 60.3514 35.388 59.7104C34.0803 59.1841 32.4376 58.9402 31.5088 60.2617C30.6901 61.5653 30.647 63.1975 31.1772 64.619C31.7756 66.5764 32.8744 68.3157 34.34 69.6886C35.1255 70.4138 36.0122 71.0284 36.9945 71.5403C38.7882 72.441 40.8046 72.8748 42.8563 72.7699C44.3777 72.7756 45.9025 72.1771 46.8521 70.9647C47.7809 69.6433 46.989 68.1889 46.0454 67.1446ZM83.9882 32.5891C83.6839 25.9725 79.9074 19.4781 74.4282 15.656L74.1926 15.4916C68.8285 11.7498 61.6701 10.439 55.3918 12.1999C51.1312 13.4383 46.2167 18.2256 43.1145 22.6392L42.3288 23.7572C40.0124 27.0529 37.8303 28.4257 34.2725 29.28C31.6579 29.9139 29.7013 31.704 28.0871 34.0007C26.4747 36.2947 25.7033 39.0021 25.9763 41.8275C26.4682 45.3295 28.3156 48.6348 31.009 50.8642C34.8172 54.2218 38.8316 57.286 43.0307 60.2152C47.2271 63.1425 51.5767 65.7354 55.9626 68.2711C58.984 70.0282 62.7336 70.6272 66.2077 69.8918C68.9662 69.181 71.2534 67.531 72.8658 65.237C74.48 62.9403 75.5019 60.4982 75.2029 57.8319C74.8522 54.2511 75.3431 51.6986 77.6595 48.4029L78.4453 47.285C81.4657 42.9876 84.2004 36.8609 83.9882 32.5891ZM74.0117 29.9549C74.6216 29.2516 75.6662 29.1673 76.3592 29.758C78.5065 31.7077 79.2032 34.8357 78.086 37.5441L77.9961 37.7761C77.6195 38.5737 76.6884 38.9456 75.8805 38.6512C75.4674 38.4408 75.1326 38.0861 74.9881 37.6468C74.8435 37.2075 74.876 36.7235 75.0861 36.2917L75.1095 36.2312C75.6377 34.8684 75.2779 33.3078 74.2092 32.3462C73.5028 31.7322 73.4214 30.6659 74.0117 29.9549Z" fill="url(#paint0_linear_2_56933)"/>
                      <path d="M23.5292 17.2585C23.5077 17.2585 23.4864 17.2542 23.4666 17.246C23.4467 17.2377 23.4287 17.2256 23.4135 17.2103C23.3983 17.195 23.3862 17.1768 23.378 17.1569C23.3698 17.1369 23.3656 17.1155 23.3656 17.0939V12.4497C23.365 12.4277 23.3687 12.4058 23.3767 12.3853C23.3846 12.3647 23.3966 12.346 23.4118 12.3302C23.4271 12.3144 23.4453 12.3019 23.4655 12.2933C23.4857 12.2847 23.5074 12.2803 23.5293 12.2803C23.5512 12.2803 23.5729 12.2847 23.5931 12.2933C23.6133 12.3019 23.6315 12.3144 23.6468 12.3302C23.6621 12.346 23.674 12.3647 23.6819 12.3853C23.6899 12.4058 23.6936 12.4277 23.693 12.4497V17.0939C23.693 17.1155 23.6888 17.1369 23.6806 17.1569C23.6723 17.1769 23.6603 17.195 23.6451 17.2103C23.6299 17.2256 23.6118 17.2377 23.5919 17.246C23.572 17.2543 23.5507 17.2585 23.5292 17.2585Z" fill="#FC6B2D"/>
                      <path d="M25.0367 14.9367H22.0218C21.9784 14.9367 21.9367 14.9193 21.906 14.8884C21.8753 14.8576 21.858 14.8157 21.858 14.772C21.858 14.7284 21.8753 14.6865 21.906 14.6556C21.9367 14.6248 21.9784 14.6074 22.0218 14.6074H25.0367C25.0801 14.6074 25.1218 14.6248 25.1525 14.6556C25.1832 14.6865 25.2005 14.7284 25.2005 14.772C25.2005 14.8157 25.1832 14.8576 25.1525 14.8884C25.1218 14.9193 25.0801 14.9367 25.0367 14.9367Z" fill="#FC6B2D"/>
                      <path d="M1.6712 35.0339C1.6497 35.0339 1.62841 35.0296 1.60855 35.0213C1.58869 35.0131 1.57065 35.0009 1.55545 34.9857C1.54026 34.9704 1.52821 34.9522 1.51999 34.9322C1.51178 34.9123 1.50756 34.8909 1.50758 34.8693V30.2251C1.50693 30.2031 1.51069 30.1812 1.51863 30.1607C1.52657 30.1401 1.53852 30.1214 1.55378 30.1056C1.56905 30.0898 1.58731 30.0772 1.60749 30.0687C1.62767 30.0601 1.64936 30.0557 1.67127 30.0557C1.69318 30.0557 1.71487 30.0601 1.73505 30.0687C1.75523 30.0772 1.77349 30.0898 1.78876 30.1056C1.80402 30.1214 1.81598 30.1401 1.82391 30.1607C1.83185 30.1812 1.83561 30.2031 1.83496 30.2251V34.8693C1.83498 34.8909 1.83076 34.9123 1.82253 34.9323C1.81431 34.9523 1.80225 34.9704 1.78704 34.9857C1.77183 35.001 1.75377 35.0131 1.73389 35.0214C1.71401 35.0297 1.69271 35.0339 1.6712 35.0339Z" fill="#FC6B2D"/>
                      <path d="M3.17867 32.712H0.163763C0.12033 32.712 0.0786767 32.6947 0.0479651 32.6638C0.0172536 32.633 0 32.5911 0 32.5474C0 32.5038 0.0172536 32.4619 0.0479651 32.431C0.0786767 32.4002 0.12033 32.3828 0.163763 32.3828H3.17867C3.22211 32.3828 3.26376 32.4002 3.29447 32.431C3.32518 32.4619 3.34244 32.5038 3.34244 32.5474C3.34244 32.5911 3.32518 32.633 3.29447 32.6638C3.26376 32.6947 3.22211 32.712 3.17867 32.712Z" fill="#FC6B2D"/>
                      <path d="M3.91122 72.4909C3.88972 72.4909 3.86843 72.4867 3.84857 72.4784C3.82871 72.4701 3.81067 72.458 3.79547 72.4427C3.78028 72.4274 3.76823 72.4092 3.76001 72.3893C3.7518 72.3693 3.74758 72.3479 3.7476 72.3263V67.6822C3.74695 67.6601 3.75071 67.6382 3.75865 67.6177C3.76659 67.5972 3.77854 67.5784 3.7938 67.5626C3.80907 67.5468 3.82733 67.5343 3.84751 67.5257C3.86769 67.5171 3.88938 67.5127 3.91129 67.5127C3.9332 67.5127 3.95489 67.5171 3.97507 67.5257C3.99525 67.5343 4.01351 67.5468 4.02878 67.5626C4.04404 67.5784 4.056 67.5972 4.06393 67.6177C4.07187 67.6382 4.07563 67.6601 4.07498 67.6822V72.3263C4.075 72.3479 4.07078 72.3693 4.06256 72.3893C4.05433 72.4093 4.04227 72.4274 4.02706 72.4427C4.01185 72.458 3.99379 72.4702 3.97391 72.4784C3.95403 72.4867 3.93273 72.4909 3.91122 72.4909Z" fill="#FC6B2D"/>
                      <path d="M5.41869 70.1691H2.40378C2.36035 70.1691 2.3187 70.1517 2.28799 70.1209C2.25727 70.09 2.24002 70.0481 2.24002 70.0045C2.24002 69.9608 2.25727 69.9189 2.28799 69.8881C2.3187 69.8572 2.36035 69.8398 2.40378 69.8398H5.41869C5.46213 69.8398 5.50378 69.8572 5.53449 69.8881C5.5652 69.9189 5.58246 69.9608 5.58246 70.0045C5.58246 70.0481 5.5652 70.09 5.53449 70.1209C5.50378 70.1517 5.46213 70.1691 5.41869 70.1691Z" fill="#FC6B2D"/>
                      <path d="M101.527 36.4581C101.484 36.4581 101.442 36.4407 101.411 36.4098C101.38 36.379 101.363 36.3371 101.363 36.2934V29.2291C101.363 29.1854 101.38 29.1435 101.411 29.1127C101.442 29.0818 101.484 29.0645 101.527 29.0645C101.57 29.0645 101.612 29.0818 101.643 29.1127C101.673 29.1435 101.691 29.1854 101.691 29.2291V36.2934C101.691 36.3371 101.673 36.3789 101.643 36.4098C101.612 36.4407 101.57 36.458 101.527 36.4581Z" fill="#FC6B2D"/>
                      <path d="M103.821 32.9259H99.2343C99.1909 32.9259 99.1492 32.9086 99.1185 32.8777C99.0878 32.8468 99.0706 32.805 99.0706 32.7613C99.0706 32.7176 99.0878 32.6758 99.1185 32.6449C99.1492 32.614 99.1909 32.5967 99.2343 32.5967H103.821C103.864 32.5967 103.906 32.614 103.936 32.6449C103.967 32.6758 103.984 32.7176 103.984 32.7613C103.984 32.805 103.967 32.8468 103.936 32.8777C103.906 32.9086 103.864 32.9259 103.821 32.9259Z" fill="#FC6B2D"/>
                      <path d="M16.9999 55.7149C16.9564 55.7149 16.9148 55.6976 16.8841 55.6667C16.8534 55.6358 16.8361 55.5939 16.8361 55.5503V48.4859C16.8361 48.4422 16.8534 48.4004 16.8841 48.3695C16.9148 48.3386 16.9564 48.3213 16.9999 48.3213C17.0433 48.3213 17.0849 48.3386 17.1157 48.3695C17.1464 48.4004 17.1636 48.4422 17.1636 48.4859V55.5503C17.1636 55.5939 17.1463 55.6358 17.1156 55.6666C17.0849 55.6975 17.0433 55.7149 16.9999 55.7149Z" fill="#FC6B2D"/>
                      <path d="M19.2934 52.1828H14.7072C14.6638 52.1828 14.6221 52.1654 14.5914 52.1345C14.5607 52.1037 14.5435 52.0618 14.5435 52.0181C14.5435 51.9745 14.5607 51.9326 14.5914 51.9017C14.6221 51.8709 14.6638 51.8535 14.7072 51.8535H19.2934C19.3369 51.8535 19.3785 51.8709 19.4092 51.9017C19.44 51.9326 19.4572 51.9745 19.4572 52.0181C19.4572 52.0618 19.44 52.1037 19.4092 52.1345C19.3785 52.1654 19.3369 52.1828 19.2934 52.1828Z" fill="#FC6B2D"/>
                      <path d="M23.8683 84.5557C23.8249 84.5557 23.7833 84.5384 23.7525 84.5075C23.7218 84.4766 23.7046 84.4348 23.7046 84.3911V77.3267C23.7046 77.2831 23.7218 77.2412 23.7525 77.2103C23.7833 77.1795 23.8249 77.1621 23.8683 77.1621C23.9118 77.1621 23.9534 77.1795 23.9841 77.2103C24.0148 77.2412 24.0321 77.2831 24.0321 77.3267V84.3911C24.0321 84.4347 24.0148 84.4766 23.9841 84.5075C23.9534 84.5383 23.9118 84.5557 23.8683 84.5557Z" fill="#FC6B2D"/>
                      <path d="M26.1619 81.0236H21.5757C21.5323 81.0236 21.4906 81.0062 21.4599 80.9754C21.4292 80.9445 21.412 80.9026 21.412 80.859C21.412 80.8153 21.4292 80.7734 21.4599 80.7426C21.4906 80.7117 21.5323 80.6943 21.5757 80.6943H26.1619C26.2054 80.6943 26.247 80.7117 26.2777 80.7426C26.3084 80.7734 26.3257 80.8153 26.3257 80.859C26.3257 80.9026 26.3084 80.9445 26.2777 80.9754C26.247 81.0062 26.2054 81.0236 26.1619 81.0236Z" fill="#FC6B2D"/>
                      <path d="M92.1184 68.8467C92.075 68.8467 92.0334 68.8294 92.0026 68.7985C91.9719 68.7676 91.9547 68.7258 91.9547 68.6821V61.6177C91.9547 61.5741 91.9719 61.5322 92.0026 61.5013C92.0334 61.4705 92.075 61.4531 92.1184 61.4531C92.1619 61.4531 92.2035 61.4705 92.2342 61.5013C92.265 61.5322 92.2822 61.5741 92.2822 61.6177V68.6821C92.2822 68.7258 92.2649 68.7676 92.2342 68.7985C92.2035 68.8293 92.1619 68.8467 92.1184 68.8467Z" fill="#FC6B2D"/>
                      <path d="M94.412 65.3146H89.8258C89.7824 65.3146 89.7407 65.2972 89.71 65.2664C89.6793 65.2355 89.662 65.1936 89.662 65.15C89.662 65.1063 89.6793 65.0644 89.71 65.0336C89.7407 65.0027 89.7824 64.9854 89.8258 64.9854H94.412C94.4555 64.9854 94.4971 65.0027 94.5278 65.0336C94.5585 65.0644 94.5758 65.1063 94.5758 65.15C94.5758 65.1936 94.5585 65.2355 94.5278 65.2664C94.4971 65.2972 94.4555 65.3146 94.412 65.3146Z" fill="#FC6B2D"/>
                      <path d="M66.2458 84.5557C66.2023 84.5557 66.1607 84.5384 66.13 84.5075C66.0992 84.4766 66.082 84.4348 66.082 84.3911V77.3267C66.082 77.2831 66.0992 77.2412 66.13 77.2103C66.1607 77.1795 66.2023 77.1621 66.2458 77.1621C66.2892 77.1621 66.3308 77.1795 66.3615 77.2103C66.3923 77.2412 66.4095 77.2831 66.4095 77.3267V84.3911C66.4095 84.4347 66.3922 84.4766 66.3615 84.5075C66.3308 84.5383 66.2892 84.5557 66.2458 84.5557Z" fill="#FC6B2D"/>
                      <path d="M68.5393 81.0236H63.9531C63.9097 81.0236 63.868 81.0062 63.8373 80.9754C63.8066 80.9445 63.7894 80.9026 63.7894 80.859C63.7894 80.8153 63.8066 80.7734 63.8373 80.7426C63.868 80.7117 63.9097 80.6943 63.9531 80.6943H68.5393C68.5828 80.6943 68.6244 80.7117 68.6551 80.7426C68.6859 80.7734 68.7031 80.8153 68.7031 80.859C68.7031 80.9026 68.6859 80.9445 68.6551 80.9754C68.6244 81.0062 68.5828 81.0236 68.5393 81.0236Z" fill="#FC6B2D"/>
                      <path d="M112.69 14.1241C112.668 14.1241 112.647 14.1199 112.627 14.1116C112.607 14.1034 112.589 14.0912 112.574 14.0759C112.559 14.0607 112.546 14.0425 112.538 14.0225C112.53 14.0025 112.526 13.9811 112.526 13.9595V10.4273C112.526 10.3837 112.543 10.3418 112.574 10.3109C112.604 10.28 112.646 10.2627 112.69 10.2627C112.733 10.2627 112.775 10.28 112.805 10.3109C112.836 10.3418 112.853 10.3837 112.853 10.4273V13.9595C112.853 13.9811 112.849 14.0025 112.841 14.0225C112.833 14.0425 112.821 14.0607 112.805 14.0759C112.79 14.0912 112.772 14.1034 112.752 14.1116C112.732 14.1199 112.711 14.1241 112.69 14.1241Z" fill="#FC6B2D"/>
                      <path d="M113.836 12.3576H111.543C111.522 12.3576 111.5 12.3533 111.48 12.345C111.461 12.3368 111.443 12.3246 111.427 12.3093C111.412 12.2941 111.4 12.2759 111.392 12.2559C111.384 12.236 111.379 12.2146 111.379 12.1929C111.379 12.1713 111.384 12.1499 111.392 12.1299C111.4 12.11 111.412 12.0918 111.427 12.0765C111.443 12.0612 111.461 12.0491 111.48 12.0408C111.5 12.0326 111.522 12.0283 111.543 12.0283H113.836C113.88 12.0283 113.921 12.0457 113.952 12.0765C113.983 12.1074 114 12.1493 114 12.1929C114 12.2366 113.983 12.2785 113.952 12.3093C113.921 12.3402 113.88 12.3576 113.836 12.3576Z" fill="#FC6B2D"/>
                      <path d="M71.6953 15.1787L73.2373 12.5079C74.0366 11.1234 75.8069 10.6491 77.1913 11.4484C78.5757 12.2477 79.0501 14.018 78.2508 15.4024L76.7088 18.0732L71.6953 15.1787Z" fill="#DCDAD5"/>
                      <defs>
                        <linearGradient id="paint0_linear_2_56933" x1="74.3739" y1="15.6181" x2="35.8362" y2="70.8638" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#DCDAD5"/>
                          <stop offset="1" stop-color="#DCDAD5" stop-opacity="0.5"/>
                        </linearGradient>
                      </defs>
                    </svg>
                    <div class="font-14px mt-5 mb-2" style="color: rgba(0, 0, 0, 0.50);font-weight: 500;">No Notifications</div>
                    <div class="font-12px pt-1" style="color: rgba(0, 0, 0, 0.50);font-weight: 300;">You haven’t received any notifications yet.</div>
                  </div>
  
                  <transition name="fade">
                    <div v-if="!showAllNotifications" class="border-top" style="border-color: rgba(220, 218, 213, 0.50);">
                      <b-btn @click="viewAll" class="border-0 text-chai font-12px mx-auto my-3" style="border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30); font-weight: 500; padding: 17px 22px; width: 230px;">
                        View All Notifications
                      </b-btn>
                    </div>
                  </transition>
                </div>
              </transition>
            </div>
          </div>
          </b-card>
        </transition>
        </div>
        <!-- sandbox and live mode toggle switch -->
        <div class="sandbox-live-mode d-flex justify-content-between px-3 my-auto">
            <p class="font-14px secondary-text my-auto" style="padding-right: 8px;">{{ liveMode ? 'Live Mode' : 'Test Mode' }}</p>
            <switch-input class="my-auto" :disableToggle="!isAdminUser || !isOnboardingCompleted" v-model="liveMode" :value="liveMode" />
        </div>

        <!-- Language Settigns -->
        <b-dropdown variant="white" toggle-class="language" menu-class="drop-menu" class="my-auto">
          <template v-slot:button-content>
            <b-img :src="current_language.flag" alt="Language" height="24" width="24" rounded="circle"></b-img>
            <span style="color: rgba(0, 0, 0, 0.50);"> {{ current_language.language.toUpperCase() }}</span>
            <img src="@/assets/images/down.svg" alt="down" height="18" width="18" />
          </template>

          <b-dropdown-item
            class="language-menu-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            @click="setLanguage(entry)"
            :link-class="{'active': entry.language === current_language.language, 'language-menu-item d-flex': true}"
          >
            <div class="my-auto">
              <b-img :src="entry.flag" v-if="entry.language == 'kr'" alt="Language" height="27" width="27" rounded="circle"></b-img>
              <b-img :src="entry.flag" v-else alt="Language" height="24" width="24" rounded="circle"></b-img>
              {{ entry.title }}
            </div>
          </b-dropdown-item>
        </b-dropdown>

        <!-- notifications toggle -->
        <div v-if="userRoles.includes('admin') || userRoles.includes('sub-admin')" style="position: relative;" class="d-flex cursor-pointer" ref="notification-button" @click="toggleNotifications">
          <div class="header-notification my-auto d-flex">
            <img src="@/assets/images/notification-bing.svg" height="20" width="20" class="mx-auto my-auto" alt="notifications">
          </div>
          <svg v-if="unreadCounts > 0" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19 18" fill="none" style="position: absolute;right: -3px;top: -3px;">
            <circle cx="9.14502" cy="9" r="9" fill="#FC6B2D"/>
          </svg>
        </div>

        <div style="width: 1px;height: 44px;position: relative;background: #DCDAD5;"></div>
        <!-- Profile Menu -->
        <b-dropdown
          right
          variant="white"
          toggle-class="profile"
          menu-class="drop-menu profile-menu"
        >
          <template v-slot:button-content>
            <div class="avtar d-flex">
              <img
                src="@/assets/images/avtar.svg"
                alt="Header Avatar"
                class="my-auto mx-auto"
              />
            </div>
            <img src="@/assets/images/down.svg" alt="down" height="18" width="18" />
          </template>

          <div class="profile-main mx-auto">
            <div class="avtar d-flex">
              <img
                src="@/assets/images/avtar.svg"
                alt="Header Avatar"
                class="my-auto mx-auto"
              />
            </div>

            <div class="profile-details">
              <p class="m-0 font-16px" style="line-height: 19px;">{{$store.state.auth.currentUser.brand_name}}</p>
              <p class="m-0 font-12px text-color-secondary" style="line-height: 14px;">{{$store.state.auth.currentUser.email_address}}</p>
              <p class="m-0 font-12px text-chai" v-if="$store.state.auth.currentUser.is_master_merchant" style="line-height: 14px;">Master Merchant</p>
            </div>
          </div>

          <routerLink class="dropdown-item profile-menu-item d-flex" :to="{name: 'General Settings', query: { tab: 'Profile' }}">
            <div class="my-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M20.5906 22C20.5906 18.13 16.7406 15 12.0006 15C7.26064 15 3.41064 18.13 3.41064 22M12.0006 12C13.3267 12 14.5985 11.4732 15.5362 10.5355C16.4739 9.59785 17.0006 8.32608 17.0006 7C17.0006 5.67392 16.4739 4.40215 15.5362 3.46447C14.5985 2.52678 13.3267 2 12.0006 2C10.6746 2 9.40279 2.52678 8.46511 3.46447C7.52743 4.40215 7.00064 5.67392 7.00064 7C7.00064 8.32608 7.52743 9.59785 8.46511 10.5355C9.40279 11.4732 10.6746 12 12.0006 12Z" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{ $t('navbar.dropdown.account-user.list.profile') }}
            </div>
          </routerLink>

          <routerLink :class="{ 'disable-side-bar': !isOnboardingCompleted }" class="dropdown-item profile-menu-item d-flex" :to="{ name: 'General Settings', query: { tab: 'Team' } }" v-if="userRoles.includes('admin') || userRoles.includes('sub-admin')">
            <div class="my-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M16.4107 4.00023C18.3507 4.00023 19.9107 5.57023 19.9107 7.50023C19.9107 9.39023 18.4107 10.9302 16.5407 11.0002C16.4543 10.9902 16.3671 10.9902 16.2807 11.0002M18.3407 20.0002C19.0607 19.8502 19.7407 19.5602 20.3007 19.1302C21.8607 17.9602 21.8607 16.0302 20.3007 14.8602C19.7507 14.4402 19.0807 14.1602 18.3707 14.0002M9.1607 10.8702C9.0607 10.8602 8.9407 10.8602 8.8307 10.8702C7.68291 10.8313 6.59535 10.3471 5.79833 9.52019C5.00132 8.69332 4.55744 7.58869 4.5607 6.44023C4.5607 3.99023 6.5407 2.00023 9.0007 2.00023C10.1769 1.97902 11.3134 2.42593 12.1602 3.24265C13.0069 4.05937 13.4945 5.179 13.5157 6.35523C13.5369 7.53147 13.09 8.66796 12.2733 9.51468C11.4566 10.3614 10.3369 10.849 9.1607 10.8702ZM4.1607 14.5602C1.7407 16.1802 1.7407 18.8202 4.1607 20.4302C6.9107 22.2702 11.4207 22.2702 14.1707 20.4302C16.5907 18.8102 16.5907 16.1702 14.1707 14.5602C11.4307 12.7302 6.9207 12.7302 4.1607 14.5602Z" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{ $t('navbar.dropdown.account-user.list.user_accounts') }}
            </div>
          </routerLink>

          <routerLink class="dropdown-item profile-menu-item d-flex" to="/logout">
            <div class="my-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15.1005 7.56023C14.7905 3.96023 12.9405 2.49023 8.89049 2.49023H8.76049C4.29049 2.49023 2.50049 4.28023 2.50049 8.75023V15.2702C2.50049 19.7402 4.29049 21.5302 8.76049 21.5302H8.89049C12.9105 21.5302 14.7605 20.0802 15.0905 16.5402M9.00049 12.0002H20.3805M18.1505 8.65023L21.5005 12.0002L18.1505 15.3502" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{ $t('navbar.dropdown.account-user.list.logout') }}
            </div>
          </routerLink>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.unread-notification {
  background: rgba(220, 218, 213, 0.30);
}

.notification-date {
  color: rgba(0, 0, 0, 0.50);
  line-height: 20px;
}

::v-deep input:checked + .slider:before {
  background-color: #FC6B2D;
}
::v-deep input:checked + .slider {
  background-color: #FFF0EA;
  border: 1px solid rgba(220, 218, 213, 0.50);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
