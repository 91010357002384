export const menuItems = [
    {
        label: "ADMINISTRATOR",
        access: ['payment-admin', 'payment-user', 'payment-refund-user','payment-links-user', 'payment-pages-user', 'invoice-user', 'payment-channel-configurator', 'recon-user', 'recon-admin', 'recon-configurator', 'sub-admin', 'payouts-admin', 'payouts-user', 'payouts-verify-user', 'payouts-approve-user', 'payout-channel-configurator'],
        merchantType:"All",
        isTitle: true
    },
    {
        label: 'menuitems.dashboard.text',
        icon: `${require('../assets/images/menu_item/dashboard.svg')}`,
        iconActive: `${require('../assets/images/menu_item/dashboard_active.svg')}`,
        access: ['payment-admin'],
        merchantType:"All",
        badge: {
            variant: "success",
            text: "menuitems.dashboard.badge"
        },
        link: '/dashboard'
    },
    {
        label: 'menuitems.transactions.text',
        icon: `${require('../assets/images/menu_item/transaction.svg')}`,
        iconActive: `${require('../assets/images/menu_item/transaction_active.svg')}`,
        link: '/transactions/payments',
        access: ['payment-admin', 'payment-user', 'payment-refund-user','payment-links-user', 'payment-pages-user', 'invoice-user', 'recon-user', 'recon-admin'],
        merchantType:"All",
        // subItems: [
        //     {

        //         label: 'menuitems.transactions.list.payments',
        //         link: '/transactions/payments',
        //         access: ['payment-admin', 'payment-user', 'payment-refund-user'],
        //         merchantType:"Both",
        //     },
            // {

            //     label: 'menuitems.transactions.list.reports',
            //     link: '/transactions/reports',
            //     access: ['payment-admin', 'payment-user', 'payment-refund-user'],
            // }
        // ]
    },
    {
        label: 'menuitems.integration.list.payment_channels',
        link: '/settings/pay-channel',
        access: ['admin', 'payment-channel-configurator', 'payment-admin', 'recon-admin', 'recon-configurator','payout-channel-configurator','payouts-admin'],
        merchantType:"MasMerchantWthMerchant",
        icon: `${require('../assets/images/menu_item/payment_channels.svg')}`,
        iconActive: `${require('../assets/images/menu_item/payment_channels_active.svg')}`,
        subItems: []
    },
    {
        label: 'menuitems.payment_link.text',
        link: '/payment-links',
        access: ['payment-links-user', 'payment-admin', 'payment-pages-user', 'invoice-user'],
        merchantType:"All",
        icon: `${require('../assets/images/menu_item/payment_link.svg')}`,
        iconActive: `${require('../assets/images/menu_item/payment_link_active.svg')}`,
        subItems: []
    },
    {
        label: 'menuitems.payment_pages.text',
        link: '/settings/payment-pages',
        access: ['payment-admin', 'payment-pages-user'],
        merchantType:"All",
        icon: `${require('../assets/images/menu_item/payment_pages.svg')}`,
        iconActive: `${require('../assets/images/menu_item/payment_pages_active.svg')}`,
        disabelBeforeOnboarding:true,
        subItems: []
    },
    {
        label: 'Invoices',
        link: '/invoices',
        access: ['payment-links-user', 'payment-admin', 'invoice-user'],
        merchantType:"All",
        icon: `${require('../assets/images/menu_item/invoices.svg')}`,
        iconActive: `${require('../assets/images/menu_item/invoices_active.svg')}`,
        disabelBeforeOnboarding:true,
        subItems: []
    },
    {
        label: 'Payment Methods',
        link: '/remittances/payment-methods',
        access: ['admin'],
        merchantType:"onlyForcrossBorder",
        icon: `${require('../assets/images/menu_item/payment_methods.svg')}`,
        subItems: []
    },
    {
        label: 'Checkout Preview',
        link: '/settings/checkout-preview?open=checkout-preview',
        access: ['admin'],
        merchantType:"onlyForcrossBorder",
        icon: `${require('../assets/images/menu_item/checkout_preview.svg')}`,
        subItems: []
    },
    {
        label: "Remittance",
        access: ['admin'],
        merchantType:"crossBorder",
        icon: `${require('../assets/images/menu_item/remittance.svg')}`,
        //isTitle: true
        subItems: [
    //},
        {
            label: 'Dashboard' ,
            link: '/remittances/dashboard',
            // icon: 'ri-link'
            access: ['admin'],
            merchantType:"crossBorder",
            icon: `${require('../assets/images/menu_item/remittance_dashbpard.svg')}`
        },
        {
            label: 'Settlement Summary',
            link: '/remittances/settlements',
            access: ['admin'],
            merchantType:"crossBorder",
            //  icon: 'ri-bank-card-2-line',
            icon: `${require('../assets/images/menu_item/settlements.svg')}`,
        },
        {
            label: 'Bank Accounts',
            link: '/remittances/bank-account',
            access: ['admin'],
            merchantType:"crossBorder",
            //  icon: 'ri-bank-card-2-line',
            icon: `${require('../assets/images/menu_item/bank_accounts.svg')}`,
        },
        {
            label: 'Remittances',
            link: '/remittances/summary',
            access: ['admin'],
            merchantType:"crossBorder",
            icon: `${require('../assets/images/menu_item/settlements.svg')}`,
        },
        {
            label: 'Reserve Ledger',
            link: '/remittances/reserve-ledger',
            access: ['admin'],
            merchantType:"crossBorder",
            icon: `${require('../assets/images/menu_item/settlements.svg')}`,
        }
    ]},
    {
        label: "menuitems.payout.text",
        access: ['payouts-admin', 'payouts-user', 'payouts-verify-user', 'payouts-approve-user'],
        merchantType:"All",
        icon: `${require('../assets/images/menu_item/payout.svg')}`,
        iconActive: `${require('../assets/images/menu_item/payout_active.svg')}`,
        disabelBeforeOnboarding:true,
        //isTitle: true
        subItems: [
    //},
        {
            label: 'menuitems.virtual_account.text' ,
            link: '/payouts/virtual-account',
            // icon: 'ri-link'
            access: ['payouts-admin', 'payouts-user', 'payouts-verify-user', 'payouts-approve-user'],
            merchantType:"All",
            icon: `${require('../assets/images/menu_item/virtual_account.svg')}`,
            iconActive: `${require('../assets/images/menu_item/virtual_account_active.svg')}`,
        },
        {
            label: 'menuitems.payout.text',
            link: '/payouts/manage-payouts',
            access: ['payouts-admin', 'payouts-user', 'payouts-verify-user', 'payouts-approve-user'],
            merchantType:"All",
            //  icon: 'ri-bank-card-2-line',
            icon: `${require('../assets/images/menu_item/payout.svg')}`,
            iconActive: `${require('../assets/images/menu_item/payout_active.svg')}`,
        },
        { 
            label: 'menuitems.beneficiary.text',
            link: '/payouts/vendors',
            // icon: 'ri-settings-line',
            access: ['payouts-admin', 'payouts-user', 'payouts-verify-user', 'payouts-approve-user'],
            merchantType:"All",
            icon: `${require('../assets/images/menu_item/beneficiary.svg')}`,
            iconActive: `${require('../assets/images/menu_item/beneficiary_active.svg')}`,
        },
        { 
            label: 'Settings',
            link: '/payouts/payout-settings',
            // icon: 'ri-settings-line',
            access: ['payouts-admin', 'payouts-user','payouts-verify-user', 'payouts-approve-user'],
            merchantType:"All",
            icon: `${require('../assets/images/menu_item/api_settings.svg')}`,
            iconActive: `${require('../assets/images/menu_item/api_settings_active.svg')}`,
        },
        // {
        //     label: 'menuitems.payout.payout_channels',
        //     link: '/settings/payout-channel',
        //     access: ['payout-channel-configurator', 'payouts-admin'],
        //     merchantType:"All",
        //     icon: `${require('../assets/images/menu_item/payout_channel.svg')}`,
        //     iconActive: `${require('../assets/images/menu_item/payout_channel_active.svg')}`,
        //     subItems: []
        // }
    ]},
    {
        label: "menuitems.recon.text",
        access: ['admin', 'recon-user', 'recon-admin', 'recon-configurator'],
        merchantType:"MasMerchantWthMerchant",
        icon: `${require('../assets/images/menu_item/recon.svg')}`,
        iconActive: `${require('../assets/images/menu_item/recon_active.svg')}`,
        disabelBeforeOnboarding:true,
        //isTitle: true
        subItems: [
        {
            id: 73,
            label: "Analytics",
            link: '/reconciliation/analytics',
            access: ['admin', 'recon-configurator', 'recon-admin'],
            merchantType:"onlyReconAnalyticsIfEnabled",
            icon: `${require('../assets/images/menu_item/analytics.svg')}`,
            iconActive: `${require('../assets/images/menu_item/analytics_active.svg')}`,
            subItems: []
        },
        {
            id: 76,
            label: "menuitems.recon.list.dashboard",
            link: '/reconciliation/dashboard',
            access: ['admin', 'recon-user', 'recon-admin'],
            merchantType:"MasMerchantWthMerchant",
            icon: `${require('../assets/images/menu_item/dashboard.svg')}`,
            iconActive: `${require('../assets/images/menu_item/dashboard_active.svg')}`,
            subItems: []
        },
        {
            label: 'menuitems.recon.list.reco',
            link: '/reconciliation/data',
            access: ['admin', 'recon-user', 'recon-admin'],
            merchantType:"MasMerchantWthMerchant",
            icon: `${require('../assets/images/menu_item/recon.svg')}`,
            iconActive: `${require('../assets/images/menu_item/recon_active.svg')}`,
            subItems: []
        },
        // {
        //     label: 'menuitems.recon.list.transaction',
        //     link: '/reconciliation/transaction',
        //     access: ['admin', 'recon-user', 'recon-admin'],
        //     merchantType:"MasMerchantWthMerchant",
        //     icon: `${require('../assets/images/menu_item/transaction.svg')}`,
        //     iconActive: `${require('../assets/images/menu_item/transaction_active.svg')}`,
        //     subItems: []
        // },
        {
            id: 72,
            label: "menuitems.recon.list.configuration",
            link: '/reconciliation/configuration',
            access: ['admin', 'recon-configurator', 'recon-admin'],
            merchantType:"MasMerchantWthMerchant",
            icon: `${require('../assets/images/menu_item/file_management.svg')}`,
            iconActive: `${require('../assets/images/menu_item/file_management_active.svg')}`,
            subItems: []
        },
        // {
        //     label: 'menuitems.recon.list.fee_management',
        //     link: '/reconciliation/fee-management',
        //     access: ['admin'],
        //     merchantType:"MasMerchantWthMerchant",
        //     icon: `${require('../assets/images/menu_item/fee_managment.svg')}`,
        //     subItems: []
        // },
        // {
        //     label: 'menuitems.recon.list.analytics',
        //     link: '/reconciliation/summary',
        //     access: ['admin'],
        //     merchantType:"MasMerchantWthMerchant",
        //     icon: `${require('../assets/images/menu_item/recon_analytics.svg')}`,
        //     subItems: []
        
        // },
        // {
        //     label: 'menuitems.recon.list.file_management',
        //     link: '/reconciliation/file-management',
        //     access: ['admin'],
        //     merchantType:"MasMerchantWthMerchant",
        //     icon: `${require('../assets/images/menu_item/file_management.svg')}`,
        //     subItems: []
        
        // },
        ]
    },
    {
        label: 'menuitems.subscriptions.text',
        link: '/subscriptions',
        access: ['admin', 'payment-admin', 'sub-admin'],
        merchantType:"All",
        icon: `${require('../assets/images/menu_item/subscription.svg')}`,
        iconActive: `${require('../assets/images/menu_item/subscription_active.svg')}`,
        disabelBeforeOnboarding:true,
        subItems: []
    },
    // {
    //     label: "menuitems.subscriptions.text",
    //     access: ['admin', 'payment-admin', 'sub-admin'],
    //     merchantType:"All",
    //     icon: `${require('../assets/images/menu_item/subscription.svg')}`,
    //     iconActive: `${require('../assets/images/menu_item/subscription_active.svg')}`,
    //     subItems: [
    //     {
    //         label: 'menuitems.plan.text',
    //         link: '/subscription-price',
    //         access: ['admin', 'payment-admin', 'sub-admin'],
    //         merchantType:"All",
    //         icon: `${require('../assets/images/menu_item/price.svg')}`,
    //         iconActive: `${require('../assets/images/menu_item/price_active.svg')}`,
    //         subItems: []
    //     },
    //     {
    //         label: 'menuitems.subscriptions.text',
    //         link: '/subscriptions',
    //         access: ['admin', 'payment-admin', 'sub-admin'],
    //         merchantType:"All",
    //         icon: `${require('../assets/images/menu_item/subscription.svg')}`,
    //         iconActive: `${require('../assets/images/menu_item/subscription_active.svg')}`,
    //         subItems: []
    //     }]
    // },
    {
        label: "Settings",//"menuitems.master_merchant.text",
        access: ['admin'],
        merchantType:"All",
        isTitle: true
    },
    {
        label: 'Settings',
        link: '/general-settings',
        access: [],
        merchantType:"All",
        icon: `${require('../assets/images/menu_item/api_settings.svg')}`,
        iconActive: `${require('../assets/images/menu_item/api_settings_active.svg')}`,
        subItems: []
    },
    {
        label: 'menuitems.master_merchant.list.merchant',
        link: '/master-merchant',
        access: ['admin'],
        merchantType:"MasterMerchant",
        icon: `${require('../assets/images/menu_item/manage-merchant.svg')}`,
        iconActive: `${require('../assets/images/menu_item/manage-merchant_active.svg')}`,
        disabelBeforeOnboarding:true,
        subItems: []
    },
    // {
    //     label: "menuitems.pay_channel_management.text",
    //     access: ['admin', 'payment-admin', 'payouts-admin'],
    //     merchantType:"All",
    //     //isTitle: true,
    //     open:false,
    //     icon: `${require('../assets/images/menu_item/api_settings.svg')}`,
    //     iconActive: `${require('../assets/images/menu_item/api_settings_active.svg')}`,
    //     subItems: [
    // //},

    // {

    //     // label: 'menuitems.integration.list.apiconfig',
    //     // access: ['admin', 'payment-admin', 'payouts-admin'],
    //     // merchantType:"Both",
    //     // icon: `${require('../assets/images/menu_item/api_settings.svg')}`,
    //     // subItems: [
    //     //     {
    //             label: 'menuitems.integration.list.api-general',
    //             access: ['admin'],
    //             merchantType:"All",
    //             link: '/integration/api-general',
    //             icon: `${require('../assets/images/menu_item/api_settings.svg')}`,
    //             iconActive: `${require('../assets/images/menu_item/api_settings_active.svg')}`,
    // },
    //         //},
    //         // {

    //         //     label: 'menuitems.integration.list.api-keys',
    //         //     access: ['admin'],
    //         //     link: '/integration/api-keys'
    //         // },
	// 		{
	// 			label: 'menuitems.integration.list.api-webhooks',
    //             access: ['admin', 'payment-admin', 'payouts-admin'],
    //             merchantType:"All",
	// 			link: '/integration/api-webhooks',
    //             icon: `${require('../assets/images/menu_item/api_settings.svg')}`,
    //             iconActive: `${require('../assets/images/menu_item/api_settings_active.svg')}`,
	// 		},
	// 		// {

	// 		// 	label: 'menuitems.integration.list.api-security',
    //         //  access: ['admin'],
	// 		// 	link: '/integration/api-security'
	// 		// }

    //     ]
    // },

    // {

    //     label: 'menuitems.integration.list.payconfig',
    //     access: ['payment-channel-configurator', 'payout-channel-configurator'],
    //     icon: 'ri-links-line',
    //     subItems: [
    //         // {

    //         //     label: 'menuitems.integration.list.pay-channels',
    //         //     link: '/integration/pay-channels',
    //         //     access: ['payment-channel-configurator', 'payout-channel-configurator']
    //         // },

    //         // {

    //         //     label: 'menuitems.integration.list.pay-checkout',
    //         //     access: ['payment-channel-configurator', 'payout-channel-configurator'],
    //         //     link: '/integration/pay-checkout'
    //         // }
    //     ]
    // },


    // {

    //     label: "menuitems.analytics.text",
    //     access: [],
    //     isTitle: true
    // },

    // {

    //     label: 'menuitems.analytics.list.revenue',
    //     access: [],
    //     icon: 'ri-line-chart-line',
	// 	   link: '/analytics/revenue'
    // },

    // {

    //     label: 'menuitems.analytics.list.monitoring',
    //     icon: 'ri-alarm-warning-line',
    //     access: [],
	// 	   link: '/analytics/monitoring'
    // },

    // {

    //     label: 'menuitems.analytics.list.paymethods',
    //     icon: 'ri-bank-card-2-line',
    //     access: [],
	// 	   link: '/analytics/paymethods'
    // },

    // {

    //     label: 'menuitems.analytics.list.env',
    //     icon: 'ri-macbook-line',
    //     access: [],
	// 	   link: '/analytics/env'
    // },


    // {

    //     label: "menuitems.account.text",
    //     access: [],
    //     isTitle: true
    // },

    // {

    //     label: 'menuitems.account.text',
    //     icon: 'ri-account-circle-line',
    //     access: [],
    //     subItems: [
    //         {

    //             label: 'menuitems.account.list.settings',
    //             access: [],
    //             link: '/account/settings'
    //         },
    //         // {

    //         //     label: 'menuitems.account.list.users',
    //         //     access: [],
    //         //     link: '/account/users'
    //         // }
    //     ]
    // },

    // {

    //     label: 'menuitems.support.text',
    //     icon: 'ri-ticket-line',
    //     access: [],
    //     subItems: [
    //         {

    //             label: 'menuitems.support.list.faq',
    //             access: [],
    //             link: '/support/faq'
    //         },
    //         {

    //             label: 'menuitems.support.list.tickets',
    //             access: [],
    //             link: '/support/tickets'
    //         }
    //     ]
    // }

]
